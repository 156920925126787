import Add from "@/assets/icons/email-admin/icon-add-white.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import UpIcon from "@/assets/icons/email-admin/icon-up-white.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import TrashRed from "@/assets/icons/email-admin/icon-trash-red.svg";
import EditIcon from "@/assets/icons/email-admin/icon-edit.svg";
import ReplaceIcon from "@/assets/icons/email-admin/icon-replace.svg";
import MenuList from "@/components/dropdown";
import EmailDays from "../../pages/email-admin/email-days";
import MenuButton from "@/components/menu-button";
import axios from "@/axios";
// import EmailTempModal from "@/components/emailtemplatemodal";
import emailTempModal from "@/components/emailtemplatemodal";
import BrowseDir from "@/components/browse-dir";
import { cloneDeep } from "lodash";
export default {
  name: "sequence",
  components: {
    MenuList,
    "trash-red": TrashRed,
    "edit-icon": EditIcon,
    "replace-icon": ReplaceIcon,
    Add,
    DropdownIcon,
    UpIcon,
    CloseIcon,
    DownIcon,
    EmailDays,
    MenuButton,
    BrowseDir,
    emailTempModal,
  },
  data() {
    return {
      templates: [],
      templateOptions: false,
      templateOptionsIndex: null,
      sequenceTemplates: [],
      isTemplateModalOpen: false,
      isCreateSequenceModal: false,
      errorMessage: '',
      directoryToastMessage: null,
    };
  },
  props: {
    handlePlusClick: {
      type: Function,
      default: () => { },
    },
    handleCreateFolder: Function,
    fetchSequenceList: Function,
    open: Boolean,
    id: {
      type: String,
      default: null,
    },
    templatesData: Array,
    isSequenceEditable: Boolean,
    populateData: Object,
    dirList: Array,
    path: Array,
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    templatesData: function (newVal) {
      this.sequenceTemplates = newVal?.map((x,i) => ({
        ...x, 
        day: x.day || newVal[i-1]?.day || 0,
      }));
    },
  },
  methods: {
    async saveTemplate(formData) {
      this.directoryToastMessage = null;
      if (formData.templateName) {
        if (formData.templateName.includes("/")) {
          this.directoryToastMessage = {
            type: 'error',
            message: `File name can't have / `,
          };
          return ;
        }
        try {
          if (formData.id) {
            await axios.post("email-sequence/update", {
              //tenant id or candidate
              id: formData.id,
              name: formData.templateName,
              description: formData.description,
              sequences: formData.sequences,
            });
            this.$toast.success("Sequence updated successfully!");
          } else {
            const sequences = this.sequenceTemplates.map((x, i) => ({
              number: i + 1,
              template_id: x.id,
              day: x.day,
            }));
            await axios.post("email-sequence/create", {
              external_id: this.$store.getters.getTenantId,
              path: `${formData?.path}/${formData.templateName}`.replace("//", "/"),
              name: formData.templateName,
              description: formData.description,
              resource_type: "resource",
              sequences: sequences,
            });
            this.$toast.success("Sequence created successfully!");
          }
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchSequenceList();
        this.toggleModal();
        this.$emit("clearSequenceDetails");
        this.hideModal();
      } else {
        this.errorMessage = 'Please enter a sequence name';
      }
    },
    showModal() {
      this.$refs.sequenceModal.showModal();
    },
    hideModal() {
      this.errorMessage = '';
      this.directoryToastMessage = null;
      this.$emit("update:open", false);
      this.$refs.sequenceModal.close();
    },
    popupbtn(i) {
      if (i === this.templateOptionsIndex) {
        this.templateOptions = false;
        this.templateOptionsIndex = null;
      } else {
        this.templateOptions = true;
        this.templateOptionsIndex = i;
      }
    },
    handleOutsideClick() {
      this.popupbtn(this.templateOptionsIndex) ;
    },
    handleClick(item) {
      if (item.key === 'remove') {
        const sequenceTemplates = cloneDeep(this.sequenceTemplates);
        const allSeq = this.populateData.sequences;
        sequenceTemplates.splice(this.templateOptionsIndex, 1);
        allSeq.splice(this.templateOptionsIndex, 1);
        this.$emit("updateSequenceData", sequenceTemplates);
        this.$emit("updateAllSequences", allSeq);
        if (!sequenceTemplates?.length)
          this.handlePlusClick();
      } else if (item.key === 'replace') {
        this.handlePlusClick(this.templateOptionsIndex);
      }
    },
    updateSequenceData(days, index) {
      const allSeq = this.populateData.sequences;
      this.sequenceTemplates[index] = { ...this.sequenceTemplates?.[index], day: days };
      allSeq[index] = { ...allSeq?.[index], day: days };
      // for (let i = index+1; i < this.sequenceTemplates?.length ; i++) {
      //   if (this.sequenceTemplates[i].day < days)
      //     this.sequenceTemplates[i].day = days;
      //   if (allSeq[i].day < days)
      //     allSeq[i].day = days;
      // }
      this.$emit("updateSequenceData", this.sequenceTemplates);
      this.$emit("updateAllSequences", allSeq);
    },
    async openTemplate() {
      this.isCreateSequenceModal = true;
      this.isTemplateModalOpen = true;
    },
    toggleModal() {
      this.errorMessage = '';
      this.directoryToastMessage = null;
      this.isTemplateModalOpen = false;
    },
    minDays(i) {
      return this.sequenceTemplates[i-1]?.day || 0;
    },
  },
  computed: {
    icons() {
      return [
        { icon: "replace-icon", label: "Replace", key: 'replace' },
        { icon: "trash-red", label: "Remove", key: 'remove' },
      ];
    },
  },
  async mounted() {
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
    }
    
  },
};
