<template>
<label
  class="checkbox-alt"
  :class="labelContainerClassList"
>
  <p v-if="label" class="truncate">{{label}}</p>
  <div
    class="input-wrapper rounded-md border-2 border-box-stroke w-5 h-5 flex items-center justify-center cursor-pointer overflow-hidden bg-white"
    :class="{'opacity-60 cursor-not-allowed': disabled}"
  >
    <transition name="move">
      <CheckIcon
        v-if="value"
        class="checkbox-alt__icon text-primary fill-current"
      />
    </transition>
    <input
      type="checkbox"
      :checked="value"
      class="hidden"
      @input="$emit('input', $event.target.checked)"
      :disabled="disabled"
    />
  </div>
</label>

</template>

<script>
import CheckIcon from '@/assets/icons/check.svg';

export default {
  name: "neo-checkbox-alt",
  components: {
    CheckIcon,
  },
  props: ["value", "label", "bg", "borderColor","disabled", 'labelContainerClassList'],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};

</script>

<style lang="scss" scoped>
.checkbox-alt {
  &__icon {
    width: 80%;
    height: auto;
  }
}

.move-enter-active, .move-leave-active {
  transition: all .1s ease-out;
}

.move-enter-from,
.move-leave-to {
  transform: translateY(3px);
  opacity: 0;
}
</style>