// import axios from "@/axios";
import Input from "@/components/input";
import CustomInput from "@/components/custom-input";
import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import DropdownIcon from "@/assets/icons/email-admin/icon-dropdown.svg";
import BoldIcon from "@/assets/icons/email-admin/icon-bold.svg";
import ItalicIcon from "@/assets/icons/email-admin/icon-italic.svg";
import UnderlineIcon from "@/assets/icons/email-admin/icon-underline.svg";
import StrikeThroughIcon from "@/assets/icons/email-admin/icon-strikethrough.svg";
import OtherIcon from "@/assets/icons/email-admin/icon-other.svg";
import ClipIcon from "@/assets/icons/email-admin/icon-clip.svg";
import CloseIcon from "@/assets/icons/email-admin/icon-crossbt.svg";
import DeleteIcon from "@/assets/icons/email-admin/icon-delete.svg";
import EditIcon from "@/assets/icons/ico-edit.svg";
import Button from "@/components/button/index.vue";
import BrowseDir from "@/components/browse-dir";
import MenuButton from "@/components/menu-button";
import FolderIcon from "../../assets/icons/email-admin/icon-folder.svg";
import Check from "../../assets/icons/email-admin/icon-check.svg";
import Search from "../../assets/icons/email-admin/icon-search.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";

import EmailPlaceholder from "./placeholders";
import FormPlaceHolder from "./form-placeholder"
// import EmailMessageField from '@shared/components/fields/email-message.vue';

import { quillEditor, Quill } from "@shared/lib/vue-quill-editor";
import { ImageExtend } from "quill-image-extend-module";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import QuillBetterTable from "quill-better-table";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register(
  {
      "modules/better-table": QuillBetterTable,
  },
  true
);
var Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block)
import { uniq } from "lodash";
import ModalContent from "@shared/modal-content";
export default {
  name: "Emailtemplatemodal",
  components: {
    quillEditor,
    "neo-input": Input,
    "neo-button": Button,
    ClipIcon,
    CloseIcon,
    texttemplate: TextTemplate,
    CustomInput,
    EditIcon,
    DeleteIcon,
    DropdownIcon,
    BoldIcon,
    UnderlineIcon,
    ItalicIcon,
    OtherIcon,
    StrikeThroughIcon,
    BrowseDir,
    Check,
    Search,
    ArrowIcon,
    FolderIcon,
    MenuButton,
    EmailPlaceholder,
    FormPlaceHolder,
    // EmailMessageField,
    ModalContent,
  },
  data() {
    return {
      data: [
        { label: "Case Studies" },
        { label: "Customers" },
        { label: "Engaged" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
        { label: "Follow up_General" },
      ],
      editorOptions: {
        debug: "info",
        placeholder: "Insert text here",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              [{ size: ["small", false, "large", "huge"] }],
              ["bold", "italic", "underline", "strike"],
              ["clean"],
            ],
          },
          table: false, 
          'better-table': {
            operationMenu: {
              items: {
                  // unmergeCells: {
                  //   text: 'Another unmerge cells name'
                  // }
              },
              color: {
                  colors: ["#fff", "red", "#d5e1df", "#e3eaa7", " #b5e7a0", "#eea29a", "#80ced6", "#f7786b", "#f4e1d2"], // colors in operationMenu
                  text: "Background Colors", // subtitle
              },
            }
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings
          },
        },
      },
      formData: {
        id: "",
        content: "",
        templateName: "",
        description: "",
        templateSubject: "",
        path: "/",
        placeholder_metadata: []
      },
      sendoptions: false,
      directoryList: [],
    };
  },
  props: {
    open: Boolean,
    populateData: Object,
    toggleModal: Function,
    dirList: Array,
    handleCreateFolder: Function,
    handleSave: Function,
    isCreateSequenceModal: {
      type: Boolean,
      default: false
    },
    errorMessage: String,
    directoryToastMessage: null, 
    path: Array,
    width: {
      type: String,
      default: '85%',
    },
  },
  computed: {
  },
  async mounted() {
    if (this.path?.length)
      this.handleSelectedPath(this.path.slice(-1)[0].path);
  },
  watch: {
    open: function (newVal) {
      if (newVal) {
        if (this.path?.length)
          this.handleSelectedPath(this.path.slice(-1)[0].path);
        this.$modal.show('emailTemplateModal');
      } else {
        this.$modal.hide('emailTemplateModal');
      }
    },
    populateData: function (newVal) {
      if (newVal) {
        this.formData = newVal;
        // this.replaceQuillPlaceholders();
        // this.$refs['subject']?.replaceQuillPlaceholders(this.formData.templateSubject, '.subject-text-area .ql-editor');
      }
    },
    dirList: function (newVal) {
      this.directoryList = newVal;
    },
  },
  methods: {
    replaceQuillPlaceholders() {
      let content = this.formData.content;
      let placeholders = content.match(/\{{(.*?)\}}/g);
      const tags = content.match(/<a(.*?)>/g);
      const quill = this.$refs.myQuillEditor?.quill;

      placeholders = uniq(placeholders?.filter((placeholder) => {
          return !tags?.some((tag) => tag.includes(placeholder))
      }));
      for (const index in placeholders) {
        if (quill) {
          const text = placeholders[index];
          let content = quill.getText();
          let sub_content = quill.getText();
          let start = 0;
          while (sub_content.indexOf(text) > -1) {
            start += sub_content.indexOf(text);
            const length = text?.length;
            this.formatText(quill, start, length);
            this.removeFormat(quill);
            start += length;
            sub_content = content.substr(start, content.length);
          } 
        }
      //   const phText = `<span class="placeholder">` + text + `</span>`;
      //   content = content.replaceAll(text, phText);
      }
    //   var editor = document.querySelector('.note-text-area .ql-editor');
    //   if (editor)
    //     editor.innerHTML = content;
    },
    insertTemplatePlaceholder(placeholder) {
      // const quill = this.$refs.myQuillEditor.$refs.editor.quill
      const quill = this.$refs.myQuillEditor.quill;
      if (quill) {
        var selection = quill.getSelection(true);
        const length = placeholder?.placeholder?.length;
        quill.insertText(selection.index, `{{${placeholder.placeholder}}}`);
        // this.formatText(quill, selection.index, length+4);
        // this.removeFormat(quill);
        quill.setSelection(selection.index+length+4);
      }
    },
    insertFormPlaceholder({id, internal_name}) {
      // if (placeholder && placeholder.type )
      // const quill = this.$refs.myQuillEditor.$refs.editor.quill
      const quill = this.$refs?.myQuillEditor?.quill;
      if (quill) {
        var selection = quill.getSelection(true);
        const length = internal_name?.length;
        quill.insertText(selection.index, `{{${internal_name}}}`);
        // this.formatText(quill, selection.index, length+4);
        // this.removeFormat(quill);
        quill.setSelection(selection.index+length+4);
      }
      let obj = {
          "placeholder": internal_name,
          "type": "form",
          "id": id
      }
      if (!this.formData.placeholder_metadata.some(el => el.id === obj.id)) this.formData.placeholder_metadata.push(obj)
    },
    formatText(quill, start, length) {
      quill.formatText(start, length, {
        color: 'rgb(13, 105, 213)',
        bold: true,
      });
    },
    removeFormat(quill) {
      quill.format('bold', false);
      quill.format('color', 'black');
    },
    async popupbtn() {
      this.sendoptions = !this.sendoptions;
      await this.saveTemplate();
    },
    placeholderClick() {
    },
    handleSelectedPath(path) {
      this.formData.path = path;
    },
    deleteTemplate() {
      this.$emit("delete", this.populateData?.id);
    },
    insertTable() {
      const betterTable = this.$refs.myQuillEditor.quill.getModule('better-table');
      betterTable.insertTable(3,3)
    },
  },

};
