import axios from "@/axios";
import Multiselect from "vue-multiselect";
import Button from "@/components/button";
import { getChecksList } from "@/modules/checks/services"
const modalConfirm = () => import("@/components/modal-confirm");
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            checks: {
                checkOptions: [],
                checkValue: [],
            },
            isLoading: false,
            addingCheck: false,
        };
    },
    components: {
        Button,
        "vue-multiselect": Multiselect,
        modalConfirm
    },
    async mounted() {
        await this.getCaseChecks()
        await this.fetchChecksList(); 
    },
    computed: {
        ...mapGetters(["getCaseData", "getRoles", "getRefChecks"]),
    },
    methods: {
        ...mapActions(["fetchCaseData", "fetchRoles", "fetchRefChecks"]),
        async fetchChecksList() {
            this.isLoading = true
            const tenant = this.$store.getters.getTenantId;
            this.checks.checkOptions = await getChecksList(tenant)
            this.isLoading = false
        },
        async removeCheck(check){
            if(check.default_added) return
            // const promise = await this.$refs["confirm-popup"].show({
            //     title: "Are you sure?",
            //     message:"This Check  will be deleted permanently. Please confirm to continue deleting this check.",
            // });
            // if (promise) {
                this.checks.checkValue = this.checks.checkValue.filter(el => el.id !== check.id)
            // }
            // this.$refs["confirm-popup"].close();
        },
        async addCheckToCase(){
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message:"You can't delete these checks after added to case",
            });
            if(promise){
                this.addingCheck = true
                let url = `/case/addon-checks/${this.$route.query.caseid}`
                let check_ids = []
                this.checks.checkValue.forEach(el => {
                    if(!el.default_added){
                        check_ids.push(el.id)
                    }
                })
                let payload = {
                    addon_checks: check_ids
                }
                try{
                    let { data } = await axios.post(url, payload);
                    this.$toast.success(data.message || 'checks added ')
                    await this.fetchRefChecks(this.$route.query.caseid)
                    this.checks.checkValue = this.checks.checkValue.map(el => ({...el, default_added: true}))
                }catch(error){
                    this.$toast.error(error.response.data.detail || 'failed to add check ')
                }
                this.addingCheck = false
            }
            this.$refs["confirm-popup"].close();
        },
        async previewCandidateForm(){
            if(!this.getRoles){
                await this.fetchRoles()
            }
            let roles = this.getRoles
            if(!this.getCaseData){
                await this.fetchCaseData(this.$route.query.caseid)
            }
            console.log(this.getCaseData ,"Case Datattatt")
            let role = roles.find(role => role.title === 'Candidate')
            console.log(role, "Role ")
            const package_id = this.getCaseData.package_id
            const routeData = this.$router.resolve({
                name: "Form Builder",
                params: {package:package_id},
                query:{role: role.id}
            });
            // this check has already been added to the case, cannot be deleted!
            window.open(routeData.href, "_blank");
        },
        async getCaseChecks(){
            this.isLoading = true
            let url = `/case/${this.$route.query.caseid}/check`
            try{
                let { data } = await axios.get(url);
                let case_checks = data.data?.map(el => ({...el, name:el.check_name, id:el.check_id, default_added:true}))
                this.checks.checkValue = case_checks
            }catch(error){
                console.log(error, ">>>>>error")
            }
            this.isLoading = false
        },
    },
};
