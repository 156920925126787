<template>
<div class="toggle-switch flex  justify-between items-center gap-2">
    <div class="label font-medium p-0">{{label}}</div>
    <div
        class="input bg-gray-300 h-4 flex items-center rounded-full p-1 duration-200 ease-in-out flex-shrink-0"
        @click.stop="changeValue(!calculatedValue)"
        :class="optionalClass"
    >
        <div
            class="toggle-view bg-white w-4 h-4 rounded-full shadow-md transform duration-200 ease-in-out"
            :class="calculatedValue ? 'ml-auto order-1' :  'mr-auto' "
        ></div>
        <div
            class="text"
            :class="calculatedValue ? 'text-white-text mr-auto' :  'ml-auto' "
        >
            {{calculatedValue ? 'Yes' :  'No'}}
        </div>
    </div>
    <slot name="mark"></slot>
</div>
</template>

<script>
export default {
    name: "toggle-switch",
    components: {},
    props: ["value", "disabled", "label"],
    data() {
        return {
            calculatedValue: false,
        };
    },
    computed: {
        optionalClass() {
            if (this.disabled) {
                if (this.calculatedValue) {
                    return "bg-blue-600 cursor-not-allowed pointer-events-none";
                }
                return "bg-gray-100 cursor-not-allowed pointer-events-none  opacity-50";
            } else if (this.calculatedValue) return "bg-brand cursor-pointer bg-blue-600";
            else return 'cursor-pointer';
        },
    },
    created() {
        this.calculatedValue = this.value;
    },
    mounted() {},
    methods: {
        changeValue(value) {
            this.calculatedValue = value;
            this.$emit('input', value);
        },
    },
};

</script>

<style lang="scss" scoped>
.toggle-switch {
    font-size: 14px;
    .input {
        width: 54px;
        height: 24px;
        box-sizing: border-box;
        .text {
            padding: 0 2px;
        }
    }
}
</style>