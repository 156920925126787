<template>
    <div>
        <div class="flex flex-col gap-2">
            <div class="flex items-center justify-between gap-3 relative">
                <input type="text" placeholder="Search" v-model="searchQuery"
                    class="w-full rounded-xl p-2 placeholder-black px-4  bg-base-300">
                <Search class="absolute right-2 bottom-2" />
            </div>
            <div style="height: 200px;" class="overflow-y-auto scroll-bar">
                <div v-if="isLoading" class="min-h-full max-h-full flex items-center justify-center">
                    <Loader height="32px" width="32px" :loading="isLoading" />
                </div>
                <div v-else-if="filteredPlaceholders && filteredPlaceholders.length > 0" >
                    <div v-for="placeholder in filteredPlaceholders" :key="placeholder.id"  class="flex cursor-pointer items-center gap-3 py-2 border-t border-t-base-200"  @click="setPlaceholder(placeholder)">
                        {{placeholder.name}}
                    </div>
                </div>
                <div v-else>
                    <Empty text="No Placeholders found" />
                </div>
            </div>
            
        </div>
    </div>

</template>


<script>
import axios from "@/axios";
import Search from "@/assets/icons/email-admin/icon-search.svg";
import Loader from "@/components/loader";
import Empty from "@shared/empty"
export default {
    name:"EmailPlaceholder",
    components: {
        Search,
        Loader,
        Empty
    },
    data(){
        return {
            placeholders:[],
            isLoading: false,
            searchQuery: "",
        }
    },
    computed:{
        filteredPlaceholders() {
            if (this.searchQuery) {
                return this.placeholders.filter((item) => {
                    return item.name?.toLowerCase()?.includes(this.searchQuery.toLowerCase());
                });
            } else {
                return this.placeholders;
            }
        }
    },
    async mounted(){
        await this.fetchPlaceholders()
    },
    methods:{
        setPlaceholder(placeholder){
            this.$emit('insertPlaceholder', placeholder)
        },
        async fetchPlaceholders(){
            this.isLoading = true
            const url = "/email-template/placeholders/all"
            try {
                const data = await axios.get(url)
                this.placeholders = data.data
            } catch(error) {
                console.log(error);
            }
            this.isLoading = false
        }
    }
}

</script>
