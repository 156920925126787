<template>
    <modal
        :name="modalName"
        :width="width"
        :maxWidth="maxWidth"
        height="auto"
        scrollable
        @closed="$emit('closed')"
        v-bind="settings"
        :clickToClose ="close"
        adaptive
    >
        <div
            class="modalContent flex flex-col"
            :class="{'modalContent--loading': loading || loadingGlobal}"
        >
            <loader
                v-if="loadingGlobal"
                class="mr-2.5 globalLoader"
                :loading="true"
                :success="success"
                :size="16"
            />
            <div class="modalContent__header py-2 px-6 flex items-center justify-between">
                <!-- <div class="inline-flex w-full"> -->
                <h3 class="text-white-text capitalize font-semibold ">
                    {{ title }}
                </h3>
                <span
                    class="cursor-pointer text-white-text cross" 
                    @click="hideModal"
                >
                    <font-awesome-icon icon="times" size="lg"/>
                </span>
                <!-- </div> -->
            </div>
                
            <div
                v-if="$slots.content"
                class="modalContent__content overflow-y-auto border-solid   p-5  xl:p-6   border-0 border-t border-gray-300"
                :class="{'bg-gray-100': darkContent}"
            >
                <slot name="content">Modal content</slot>
            </div>
            <div class="modalContent__footer p-5 pt-0 xl:p-6 xl:pt-0 flex justify-end" v-if="showFooter">
                <slot name="footer">
                    <Button
                        v-if="additionalBtn"
                        v-text="additionalBtn"
                        :bg="loading ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                        color="white"
                        hoverBg="var(--brand-hover-on)"
                        hoverText="white"
                        padding="0.4rem 0.7rem"
                        borderRadius="0.3rem"
                        class="btn btn-outline btn-info bottom-8 right-8 w-auto mr-2.5"
                        @click="$emit('alt-btn')"
                        @keyup.enter="$emit('alt-btn')"
                    />
                    <Button
                        :disabled="disabled || loading || loadingGlobal"
                        :bg="loading || loadingGlobal ? 'var(--brand-accent-neutral-alt)' : 'var(--brand-color)'"
                        class="btn btn-info bottom-8 right-8 w-auto"
                        @click="submitModal"
                        @keyup.enter="submitModal"
                        :text="submitButtonText"
                        :loader="loading"
                    >
                        <loader
                            v-if="loading"
                            class="mr-2.5"
                            :loading="true"
                            :size="4"
                        />
                    </Button>
                </slot>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from "@/components/button";
import loader from "@/components/loader";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import '@/assets/mixins/mixins.scss';

export default {
    name: "modal-content",
    components: {
        loader,
        "font-awesome-icon": FontAwesomeIcon,
        Button,
    },
    props: {
        name: {
            type: String,
            default: () => 'new-modal',
        },
        title: {
            type: String,
            default: () => 'Modal Title',
        },
        close: {
            type: Boolean,
            default: () => false,
        },
        submitButtonText: {
            type: String,
            default: () => 'Save',
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        loadingGlobal: {
            type: Boolean,
            default: () => false,
        },
        success: { // TODO check and remove
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        darkContent: {
            type: Boolean,
            default: () => false,
        },
        width: {
            type: String,
            default: () => '50%',
        },
        maxWidth: {
            type: Number,
        },
        settings: {
            type: Object,
            default: () => ({}),
        },
        additionalBtn: {
            type: String,
            default: () => '',
        },
        showFooter:{
            type:Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    computed: {
        modalName() {
            return `modal-content--${this.name}`
        },
    },
    mounted() {},
    methods: {
        showModal() {
            this.$modal.show(this.modalName);
        },
        hideModal() {
            this.$modal.hide(this.modalName);
        },
        submitModal() {
            this.$emit('submit');
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .vm--modal {
        border-radius: 1rem !important;
    }
}
.modalContent {
    &--loading {
        cursor: not-allowed;
        * {
            pointer-events: none;
        }
    }
    max-height: 90vh;
    &__header {
        @apply bg-primary;
        .title {
            color: #fff;
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            font-weight: normal;
            line-height: 22px;
        }
    }
    &__footer {
        // padding-top: 20px;
        padding-bottom: 20px;
        &__button {
            min-width: 132px;
            min-height: 35px;
        }
    }
    .globalLoader {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}
</style>